import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import Button from '../../components/button/button'
import { toLink } from '../../utils'
import * as s from '../../pages/news.module.scss'

const SignalToolsWebinar: React.FC = () => {
  const description =
    'Приглашаем посетить бесплатный вебинар, посвященный возможностям программы SIGNAL TOOLS для работы с документацией в AutoCAD'
  return (
    <>
      <SEO title="SIGNAL TOOLS Webinar" description={description} />
      <NewsSection
        title="BIM для тех кто в каске. Контроль выполненных строительных работ c помощью AutoCAD"
        dateData={{
          date: '27.07.2023',
        }}
        maxContent
        rayContent
      >
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'my-4')}
            src="../../assets/images/news/2023-07-27-signal-tools-webinar/image-1.png"
            alt="BIM для тех кто в каске"
            title="BIM для тех кто в каске"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Приглашаем посетить бесплатный вебинар, посвященный возможностям
          программы SIGNAL TOOLS для работы с документацией в AutoCAD
        </p>
        <p className={s.text}>
          <strong>Дата: 03 августа 2023 / 11:00 МСК</strong>
        </p>
        <p className={s.text}>Что вас ждет:</p>
        <ul className={cn(s.textLists, s.disc)}>
          <li>
            Создание строительной модели с помощью SIGNAL TOOLS в AutoCAD;
          </li>
          <li>Фиксация выполненных объемов работ;</li>
          <li>Визуализация хода строительства на чертежах AutoCAD.</li>
          <li>Применение BIM-технологии с помощью AutoCAD+Excel.</li>
          <li>Применение AutoCAD как информационно-аналитическую систему.</li>
          <li>Оперативное и прозрачное управление выполненными работами.</li>
          <li>
            Анализ данных в разрезе:по периоду, подрядчикам, объемам работ.
          </li>
          <li>
            Экспорт выполненных объемов работ в Excel, в т.ч по форме КС-2;
          </li>
        </ul>
        <p className={s.text}>
          После демонстрации у вас будет возможность задать вопросы директору по
          цифровому строительству и получить бесплатную версию программы SIGNAL
          TOOLS FREE
        </p>
        <div className="flex items-center flex-wrap">
          <StaticImage
            className="my-4 mr-6"
            src="../../assets/images/news/2023-07-27-signal-tools-webinar/image-2.png"
            alt="Сергей Казанцев"
            title="Сергей Казанцев"
            placeholder="blurred"
          />
          <div>
            <p className={cn(s.text, 'm-0')}>Спикер: Сергей Казанцев</p>
            <p
              className={cn(s.text, 'm-0')}
            >{`Директор по цифровому строительству\n SIGNAL`}</p>
          </div>
        </div>
        <p className={s.text}>
          Вебинар будет интересен всем участникам строительного процесса, кто в
          работе использует AutoCAD и Excel, в т.ч. инженерам ПТО, линейным
          сотрудникам ИТР, специалистам строительного контроля, менеджерам
          проекта.
        </p>
        <div className="w-100 flex justify-center flex-col items-center">
          <Button
            className="my-4"
            onClick={() =>
              toLink(
                'https://us06web.zoom.us/meeting/register/tZMucOisrzwuG9HM3F7Jq1XmRaSSINzvmfO5'
              )
            }
          >
            Регистрация
          </Button>
        </div>
        <p className={s.text}>
          Не упустите шанс получить знания и овладеть новыми навыками в области
          цифровизации 2D-строительства
        </p>
        <p className={s.text}>До встречи на вебинаре!</p>
      </NewsSection>
    </>
  )
}

export default SignalToolsWebinar
